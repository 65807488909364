import React, { useState } from 'react';
import Navbar from './Navbar';
import '../styles/Tentti.css';

const Tentti = () => {
    const [showLinks, setShowLinks] = useState(false);
  
    return (
      <div className='screen'>
        <div className="tentti">
        <Navbar showLinks={showLinks} setShowLinks={setShowLinks} />
        <div className='tentti-pic-frame' style={{ backgroundImage: `url(/tentti-image.jpeg)`}} loading="lazy" decoding="async" >
        <div className='tentti-main'>
          <h1>Tenttivaatimukset</h1>
          <p>Romaniassa huippuyliopistot eivät vaadi kemiaa tai fysiikkaa. Pääsykokeissa keskitytään anatomiaan ja fysiologiaan. Koe on pääpainoitteisesti suullinen</p>
        </div>
        </div>
        </div>

        <div className='myTentti'>
        <h1>Pääsykoe koostuu kahdesta vaiheesta</h1>
        <div className='tentti-container'>
        <div className="tentti-text1">
          <h2>Englannin kielen koe</h2>
            <p>Kokeessa tarkistetaan englannin kielen tasosi. Suomessa yleissivistävän koulutuksen suorittanut pärjää kokeessa hyvin kokemuksien mukaan.</p>
            <p>Haastattelu tilanteessa sinua haastattelee 2-3 professoria. Kysymykset ovat yleensä motivaatio pohjaisia (esim. miksi sinä ansaitsisit opiskelupaikan yliopistossa). Englannin kielen kirjallinen koe taas puolestaan koostuu monivalintatehtävistä sekä pieni muotoisesta luku harjoituksesta. Tämän lisäksi joissain koulussa kirjoitat motivaatiokirjeen koe tilaisuudessa.</p>
        </div>

        <div className="tentti-text2">
          <h2>Anatomian & Fysiologian koe</h2>
            <p>Koe on suullinen ja hyvin laaja. Professorit voivat kysyä mitä tahansa koskien ihmisen anatomiaa tai physiologiaa. Kokeessa ei katsota ainoastaan aihealueiden osaamista vaan niiden esittämistä ammattikielellä.</p>
            <p>Suullinen pääsykoe kestää koko päivän tyypillisesti klo 9-21. Tämä johtuu siitä, että kaikki hakijat täytyy ehtiä haastattelun läpi. Pidä huoli siitä, että tiedät, milloin sinut kutsutaan haastatteluun ja missä välissä on taukoja. Koska odotukset kokeiden välillä kestävät useita tunteja. Odottaessa voit myös käydä lepäämässä, tankkaamassa tai lukemassa. Kirjallinen pääsykoe tilaisuus on suomalaisille opiskelijoille tuttua. Eli koe alkaa 9:ltä aamulla ja kestää klo 12:00 asti. Kokeet ovat monivalinta kysymyksiä anatomian ja physiologian opinnoista.</p>
        </div>
        </div>
        </div>
    </div>

    );
};

export default Tentti;